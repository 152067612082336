nav {
  background: rgba(0, 0, 0, 0.3);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.8rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.1rem;
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
}

nav a.active {
  background: var(--color-primary);
  color: var(--color-bg);
}

.under__construction {
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: -1;
  position: fixed;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  top: -1.5rem;
  left: -2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  /* backdrop-filter: blur(15px); */
}

.fadeOut {
  visibility: visible;
  opacity: 0.1;
  transition: opacity 2s 5s;
}

/* ============== HEADER SOCIALS ================= */

.header__socials {
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  top: 50%;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* .scroll_down {
    width: max-content;
    position: fixed;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    right: -2.3rem;
    top: 50%;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
  } */

/* ================ MEDIA QUERIES (SMALL DEVICES) ======================= */
@media screen and (max-width: 600px) {
  .header__socials,
  .scroll_down,
  .me {
    display: none;
  }

  .under__construction {
    width: 60%;
      }
}
